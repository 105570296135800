<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="56"
    height="24"
    viewBox="0 0 56 24"
    fill="none"
  >
    <path
      d="M27 24H20C18.8954 24 18 23.1046 18 22V12H27V24ZM27 6H17.5C16.6716 6 16 6.67157 16 7.5V8.5C16 9.32843 16.6716 10 17.5 10H27V6ZM29 24H36C37.1046 24 38 23.1046 38 22V12H29V24ZM29 6V10H38.5C39.3284 10 40 9.32843 40 8.5V7.5C40 6.67157 39.3284 6 38.5 6H29ZM33.369 0C30.422 0 28.698 3.477 28 5H33.345C36.838 5 36.875 0 33.369 0ZM32.573 3.621H30.53C31.269 2.5 31.969 1.655 32.872 1.655C34.044 1.655 34.1 3.621 32.573 3.621ZM22.655 5H28C27.302 3.477 25.578 0 22.631 0C19.125 0 19.162 5 22.655 5ZM23.128 1.655C24.031 1.655 24.731 2.5 25.47 3.621H23.427C21.9 3.621 21.956 1.655 23.128 1.655Z"
      fill="#000"
    />
  </svg>
</template>
<script lang="ts">
export default defineComponent({
  name: 'IconNavBenefit',
})
</script>
