<template>
  <ZipupContainer
    row
    around
    inner
    class="w-full bg-white border-t-gray-100 shadow px-2 pt-2"
    :class="safe ? 'safe-inset-b' : ''"
  >
    <ZipupButtonIcon
      v-for="item in navBarItems"
      class="flex flex-col items-center justify-center gap-0.5"
      @click="onClick(item)"
    >
      <component
        :is="
          $route.path.indexOf(item.link) === -1 ? item.icon[1] : item.icon[0]
        "
      >
      </component>

      <div
        class="text-2sm whitespace-nowrap"
        :class="
          $route.path.indexOf(item.link) === -1
            ? 'text-[#A1A1A5]'
            : 'text-black font-medium'
        "
      >
        {{ item.name }}
      </div>
    </ZipupButtonIcon>
  </ZipupContainer>
</template>
<script>
import IconNavCalendar from '~/components/icon/IconNavCalendar'
import IconNavCalendarFilled from '~/components/icon/IconNavCalendarFilled'
import IconNavHome from '~/components/icon/IconNavHome.vue'
import IconNavHomeFilled from '~/components/icon/IconNavHomeFilled.vue'
import IconNavSearch from '~/components/icon/IconNavSearch.vue'
import IconNavSearchFilled from '~/components/icon/IconNavSearchFilled.vue'
import IconNavBenefit from '~/components/icon/IconNavBenefit.vue'
import IconNavBenefitFilled from '~/components/icon/IconNavBenefitFilled.vue'
import IconNavProfile from '~/components/icon/IconNavProfile.vue'
import IconNavProfileFilled from '~/components/icon/IconNavProfileFilled.vue'
export default defineComponent({
  props: {
    safe: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      url: '',
      navBarItems: [
        {
          link: '/app/home',
          name: '홈',
          icon: [markRaw(IconNavHomeFilled), markRaw(IconNavHome)],
        },
        {
          link: '/app/planner',
          name: '이사 플래너',
          icon: [markRaw(IconNavCalendarFilled), markRaw(IconNavCalendar)],
          hasTooltip: true,
        },
        {
          link: '/app/sales',
          name: '집 탐색',
          icon: [markRaw(IconNavSearchFilled), markRaw(IconNavSearch)],
        },
        {
          link: '/app/benefits',
          name: '혜택',
          icon: [markRaw(IconNavBenefitFilled), markRaw(IconNavBenefit)],
        },
        {
          link: '/app/myzipup',
          name: '나의 집업',
          icon: [markRaw(IconNavProfileFilled), markRaw(IconNavProfile)],
        },
      ],
    }
  },
  methods: {
    onClick(item) {
      this.$amplitude.track('click_nav_item', {
        type: item.name,
      })
      this.$router.push(item.link)
    },
  },
})
</script>
<style lang="postcss" scoped>
.shadow {
  box-shadow: 0 -2px 8px -1px rgba(0, 0, 0, 0.07);
}
</style>
