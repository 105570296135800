<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="56"
    height="24"
    viewBox="0 0 56 24"
    fill="none"
  >
    <path
      d="M28 12C29.667 12 31.2658 11.3679 32.4447 10.2426C33.6234 9.11742 34.2858 7.5913 34.2858 6C34.2858 4.4087 33.6234 2.88258 32.4447 1.75736C31.2658 0.632142 29.667 0 28 0C26.3329 0 24.7341 0.632142 23.5553 1.75736C22.3765 2.88258 21.7143 4.4087 21.7143 6C21.7143 7.5913 22.3765 9.11742 23.5553 10.2426C24.7341 11.3679 26.3329 12 28 12ZM25.7558 14.2501C20.9188 14.2501 17 17.9906 17 22.6078C17 23.3766 17.6531 24 18.4585 24H37.5415C38.3468 24 39 23.3766 39 22.6078C39 17.9906 35.0812 14.2501 30.2442 14.2501H25.7558Z"
      fill="#A1A1A5"
    />
    <!-- <g clip-path="url(#clip0_6876_12508)">
 
    </g> -->
    <!-- <defs>
      <clipPath id="clip0_6876_12508">
        <rect width="22" height="24" fill="white" transform="translate(17)" />
      </clipPath>
    </defs> -->
  </svg>
</template>
<script lang="ts">
export default defineComponent({
  name: 'IconMemuProfile',
})
</script>
