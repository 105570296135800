<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="56"
    height="24"
    viewBox="0 0 56 24"
    fill="#A1A1A5"
  >
    <path
      d="M27.6121 19.1297C27.1946 19.6078 26.4482 19.6078 25.9866 19.1297L22.8438 16.1297C22.3871 15.7313 22.3871 15.0188 22.8438 14.5781C23.3054 14.1422 24.0518 14.1422 24.4692 14.5781L26.8214 16.7859L31.4866 12.3281C31.9482 11.8922 32.6947 11.8922 33.112 12.3281C33.613 12.7688 33.613 13.4813 33.112 13.8797L27.6121 19.1297ZM23.2857 0C23.9388 0 24.4643 0.503906 24.4643 1.125V3H31.5357V1.125C31.5357 0.503906 32.0611 0 32.7143 0C33.3674 0 33.8929 0.503906 33.8929 1.125V3H35.8571C37.5907 3 39 4.34297 39 6V21C39 22.6547 37.5907 24 35.8571 24H20.1429C18.4069 24 17 22.6547 17 21V6C17 4.34297 18.4069 3 20.1429 3H22.1071V1.125C22.1071 0.503906 22.6326 0 23.2857 0ZM36.6429 9H19.3571V21C19.3571 21.4125 19.7088 21.75 20.1429 21.75H35.8571C36.2893 21.75 36.6429 21.4125 36.6429 21V9Z"
      fill="#A1A1A5"
    />
  </svg>
</template>
<script lang="ts">
export default defineComponent({
  name: 'IconNavCalendar',
})
</script>
