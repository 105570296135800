<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="56"
    height="24"
    viewBox="0 0 56 24"
    fill="none"
  >
    <path
      d="M38.5 22.5L32.4371 16.4371M32.4371 16.4371C34.078 14.7962 34.9999 12.5706 34.9999 10.2499C34.9999 7.92932 34.078 5.70373 32.4371 4.0628C30.7962 2.42187 28.5706 1.5 26.2499 1.5C23.9293 1.5 21.7037 2.42187 20.0628 4.0628C18.4219 5.70373 17.5 7.92932 17.5 10.2499C17.5 12.5706 18.4219 14.7962 20.0628 16.4371C21.7037 18.078 23.9293 18.9999 26.2499 18.9999C28.5706 18.9999 30.7962 18.078 32.4371 16.4371Z"
      stroke="#A1A1A5"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script lang="ts">
export default defineComponent({
  name: 'IconNavSearch',
})
</script>
