<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="56"
    height="24"
    viewBox="0 0 56 24"
    fill="none"
  >
    <path
      d="M28 0C28 0 19.9582 7.12 15.4641 10.976C15.2002 11.2213 15 11.5787 15 12C15 12.7373 15.5811 13.3333 16.3 13.3333H18.9V22.6667C18.9 23.404 19.4811 24 20.2 24H24.1C24.8189 24 25.4 23.4027 25.4 22.6667V17.3333H30.6001V22.6667C30.6001 23.4027 31.1812 24 31.9 24H35.8C36.5189 24 37.1 23.404 37.1 22.6667V13.3333H39.7C40.4189 13.3333 41 12.7373 41 12C41 11.5787 40.7998 11.2213 40.5022 10.976C36.0392 7.12 28 0 28 0Z"
      fill="#000"
    />
  </svg>
</template>

<script lang="ts">
export default defineComponent({
  name: 'IconNavHomeFilled',
})
</script>
